<template>
  <div>
    <h2>Case Video -
      <router-link :to="'/cases/' + caseObject.CaseID + '/detail'">
        <span class="pl-1 text-nowrap">{{ caseObject.FileNumber }}</span>
      </router-link>
    </h2>
    <hr>
    <b-row v-if="!isPlay">
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          :variant="'outline-primary'"
          class="mr-1"
          @click.prevent="playAllCaseVideos()"
        >
          Play All Case Videos
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <!-- Table -->
      <b-col
        v-if="!isPlay"
        class="white-bg-container"
      >
        <b-row>
          <b-col class="text-center">
            Clip/View
          </b-col>
          <b-col>Duration</b-col>
        </b-row>
        <b-row
          v-for="(item, index) of Videos"
          :key="index"
        >
          <b-col>
            <b-row class="pt-1 border-top">
              <b-col>
                <div
                  class="video-container"
                  @click="SelectedVideo = item"
                >
                  <img :src="'https://api.nimbushd.com/Imaging/Thumbnail.ashx?id=' + item.VideoID">
                </div>
                <div class="d-inline-block">
                  <div>
                    <router-link :to="'/cases/view-activity/' + item.Meta.ActivityID + '/VIDEO'">
                      <span class="pl-1 text-nowrap">{{ item.Title }}</span>
                    </router-link>
                    <feather-icon
                      icon="DownloadIcon"
                      class="ml-2 cursor-pointer font-medium-2"
                      @click="downloadVideo(item.VideoID)"
                    />
                  </div>
                  <div class="pl-1">{{ item.Meta.ScheduledDate | dateFormat }}</div>
                </div>
              </b-col>
              <b-col>
                <span>{{ item.DurationMs | minutesFormat }}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div v-if="isPlay">
      <b-row class="pt-3">
        <b-col>
          <div>{{ TotalVideos }} Videos Total Duration: {{ TotalDuration | minutesFormat }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="video-list">
          <b-row
            v-for="(item, index) of Videos"
            :key="index"
            class="d-flex align-items-center"
            :class="{'active-video': item.VideoID === SelectedVideo.VideoID}"
          >
            <b-col md="6">
              <b-row class="pt-1 border-top">
                <b-col md="8">
                  <div
                    class="video-content cursor-pointer"
                    @click="SelectedVideo = item"
                  >
                    <img :src="'https://api.nimbushd.com/Imaging/Thumbnail.ashx?id=' + item.VideoID">
                  </div>
                </b-col>
                <b-col
                  md="4"
                  class="pl-2"
                >
                  <div class="text-nowrap">
                    {{ item.Title }}
                  </div>
                  <div class="text-nowrap">
                    {{ item.DurationMs | minutesFormat }}
                  </div>
                  <div class="download-icon-wrapper">
                    <feather-icon
                      icon="DownloadIcon"
                      class="ml-2 cursor-pointer font-medium-2"
                      @click="downloadVideo(item.VideoID)"
                    />
                  </div>
                </b-col>

              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="SelectedVideo"
          md="8"
          style="max-height: 20rem;"
        >
          <b-row>
            <b-col>
              <div class="text-wrapper">
                If video is in an archived state, you can request the video to be reactivated for playback or download.
                Send requests to: <span class="email-wrapper">
                  <a :href="mailtoMsg">
                    {{ videoManagerEmail }}
                  </a>
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>
                <h2 class="text-nowrap">{{ SelectedVideo.Title }}
                  <feather-icon
                    icon="DownloadIcon"
                    class="cursor-pointer font-medium-3"
                    @click="downloadVideo(SelectedVideo.VideoID)"
                  />
                </h2>
              </span>
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                :variant="'outline-secondary'"
                class="mr-1 capture-btn"
                @click.prevent="captureFrameImage()"
              >
                Capture Frame Image(s)
              </b-button>
            </b-col>
          </b-row>
          <iframe
            v-if="SelectedVideo.EmbedCode"
            id="videoPlayer"
            :src="'/api/file/get-video-script/' + SelectedVideo.EmbedCode + '&w=640&h=400'"
          />
        </b-col>
      </b-row>
      <VideoCapture
        v-if="CaptureFrame"
        :video="SelectedVideo"
        @close="CaptureFrame = false"
      />
    </div>
  </div>
</template>

<script>

import APIService from "@core/utils/APIService";
import {BButton, BCol, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VideoCapture from "@/views/cases/components/VideoCapture";
import {mapMutations} from "vuex";
import axios from "axios";
import { saveAs } from 'file-saver';
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {videoManagerEmail} from "@/config/config";

const axiosIns = axios.create({
  responseType: 'blob',
  headers: {
    'Content-Type': 'video/mp4',
  },
})

const apiService = new APIService();

export default {
  components: {
    VideoCapture,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  filters: {
    minutesFormat(val) {
      const minutes = Math.floor(val / 1000 / 60);
      const seconds = Math.floor(val / 1000) - minutes * 60;
      const minutesTxt = minutes === 1 ? " minute " : " minutes "
      const secondsTxt = minutes === 1 ? " second " : " seconds "
      return minutes + minutesTxt + seconds + secondsTxt;
    },
    dateFormat(val) {
      if (val) {
        const date = new Date(val);
        const options = {month: '2-digit', day: '2-digit', year: 'numeric'}
        if (date) return date.toLocaleDateString("en-US", options);
      }
      if (val) return val.substr(0, 10);
      return null
    },
  },
  data() {
    return {
      CaseID: "",
      caseObject: {},
      Videos: [],
      SelectedVideo: {},
      stopVideo: false,

      TotalVideos: 0,
      TotalDuration: 0,
      isPlay: false,
      CaptureFrame: false,
      videoManagerEmail: videoManagerEmail,
      mailtoMsg: ""
    }
  },
  watch: {
    SelectedVideo(val) {
      if (val) {
        setTimeout(() => {
          this.nextVideo(500)
        }, 1500)
      }
    }
  },
  created() {
    this.CaseID = this.$route.params.caseId;
    this.loadCase();
    this.loadVideo();
  },
  methods: {
    ...mapMutations({
      setVideoTime: "video/setVideoTime"
    }),

    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Text copied to clipboard',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },

    loadCase() {
      apiService
          .get("case/" + this.CaseID + "/detail")
          .then((res) => {
            this.caseObject = res.data;
            this.mailtoMsg = "mailto:" + this.videoManagerEmail + "?subject=Request to Wake Video for Case " +
                res.data.FileNumber + "&body=Please reactivate the video for streaming on this claim for the following dates:"
          })
    },

    loadVideo() {
      apiService
          .get("case/" + this.CaseID + "/videos")
          .then((res) => {
            this.TotalDuration = 0;
            this.Videos = res.data;
            this.Videos = this.Videos.map((i, index) => { return {...i, index}})
            for (const i in this.Videos) {
              this.TotalDuration = this.TotalDuration + this.Videos[i].DurationMs;
            }
            this.TotalVideos = this.Videos.length;
            this.SelectedVideo = this.Videos[0];
          })
    },

    captureFrameImage() {
      const el = document.getElementById("videoPlayer")
      if (el) {
        const vid = el.contentDocument
        const controlTime = vid.querySelector("div.jw-text-elapsed").textContent
        this.setVideoTime(controlTime)
        this.CaptureFrame = true;
      }
     },

    nextVideo(time) {
      setTimeout(() => {
        const el = document.getElementById("videoPlayer")
        if (el) {
          const vid = el.contentDocument;
          const elapsed = vid.querySelector("div.jw-text-elapsed").textContent;
          const duration = vid.querySelector("div.jw-text-duration").textContent;
          const positionElapsed = Number(elapsed.split(":")[0]) * 60 + Number(elapsed.split(":")[1]);
          const positionDuration = Number(duration.split(":")[0]) * 60 + Number(duration.split(":")[1]);
          if (positionElapsed !== positionDuration) this.stopVideo = false
          if (positionElapsed === positionDuration && positionDuration !== 0 && !this.stopVideo) {
            const index = this.SelectedVideo.index + 1 >= this.Videos.length ? this.SelectedVideo.index : this.SelectedVideo.index + 1;
            if (index === this.SelectedVideo.index) return;
            this.stopVideo = true;
            this.SelectedVideo = this.Videos[index]
          } else {
            this.nextVideo(500)
          }
        }
      }, time)
    },

    playAllCaseVideos() {
      this.isPlay = true;
    },

    downloadVideo(VideoID) {
      axiosIns.get("/api/video/" + VideoID + "/download")
          .then(response => {
            saveAs(new File([response.data], "", {type: 'video/mp4'}))
          })
    },
  }
}
</script>

<style scoped>
.video-content img,
.video-container img {
  width: inherit;
  height: inherit;
}
.video-container {
  display: inline-block;
  background-color: grey;
  height: 9rem;
  width: 16rem;
  position: relative;
  top: -0.5rem;
}
#videoPlayer {
  width: 100%;
  height: 150%;
  min-height: 30rem;
}
.video-content {
  display: inline-block;
  background-color: grey;
  height: 4.5rem;
  width: 8rem;
  position: relative;
  top: -0.5rem;
}
.capture-btn {
  margin-bottom: 0.2rem;
}
.video-list {
  max-height: 50rem;
  overflow: auto;
}
.active-video {
  border: 1px solid red;
}
.download-icon-wrapper {
  position: absolute;
  left: 12rem;
  top: 1rem;
}
.text-wrapper {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-bottom: 1rem;
}
.email-wrapper {
  color: blue;
  cursor: pointer;
}
</style>